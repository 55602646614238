export const FORMATS = {
  leaderboard: {
    desktop: [[728, 90], [970, 90], [970, 250]],
    tablet: [[728, 90]],
    mobile: [[320, 50], [300, 50]]
  },
  leaderboardsmall: {
    desktop: [[468, 60]],
    tablet: [[468, 60]],
    mobile: [[320, 50]]
  },
  leaderboardtop: {
    desktop: [[728, 90], [970, 90]],
    tablet: [[728, 90]],
    mobile: [[300, 50], [320, 50]]
  },
  mpu: {
    desktop: [[300, 250]],
    tablet: [[300, 250]],
    mobile: [[300, 250]]
  },
  inarticle: {
    desktop: [[300, 250]],
    tablet: [[300, 250]],
    mobile: [[300, 250], [336, 280]]
  },
  mpudynamic: {
    desktop: [[300, 600]],
    tablet: [[300, 250]],
    mobile: [[300, 250]]
  },
  mpu600: {
    desktop: [[300, 600], [120, 600], [160, 600]],
    tablet: [[300, 600], [336, 280]],
    mobile: [[300, 250], [336, 280]]
  },
  wingbanner: {
    desktop: [[160, 600]],
    tablet: [],
    mobile: []
  },
  interstitial: {
    desktop: [[970, 600], [300, 600], [336, 280], [300, 250]],
    tablet: [[300, 600], [336, 280], [300, 250]],
    mobile: [[300, 600], [336, 280], [300, 250]]
  },
  halfpage: {
    desktop: [[300, 600]],
    tablet: [[300, 600]],
    mobile: [[300, 600]]
  },
  mobileLeaderboardSticky: {
    desktop: [],
    tablet: [],
    mobile: [[300, 50], [320, 50], [300, 100], [320, 100]]
  }
}

export const MAPPINGS = {
  'Third-Party': [
    [[1400, 0], [1, 2]],
    [[1200, 0], [1, 2]],
    [[1000, 0], [1, 2]],
    [[700, 0], [1, 2]],
    [[500, 0], [1, 2]],
    [[200, 0], [1, 2]],
    [[0, 0], []]
  ],
  'Main-Leaderboard': [
    [[1400, 0], [[1000, 250], [1000, 90], [970, 250], [970, 90], [728, 90]]],
    [[1200, 0], [[1000, 250], [1000, 90], [970, 250], [970, 90], [728, 90]]],
    [[1000, 0], [[1000, 250], [1000, 90], [970, 250], [970, 90], [728, 90]]],
    [[700, 0], [[728, 90], [468, 60], [320, 100], [320, 50], [300, 100], [300, 50]]],
    [[500, 0], [[468, 60], [320, 100], [320, 50], [300, 100], [300, 50]]],
    [[200, 0], [[320, 100], [320, 50], [300, 100], [300, 50]]],
    [[0, 0], []]
  ],
  Leaderboard: [
    [[1400, 0], [[728, 90], [468, 60], [300, 250], [336, 280]]],
    [[1400, 0], [[728, 90], [468, 60], [300, 250], [336, 280]]],
    [[1000, 0], [[468, 60], [300, 250], [336, 280], [320, 50], [300, 100], [300, 50]]],
    [[700, 0], [[468, 60], [320, 100], [320, 50], [300, 100], [300, 50], [300, 250], [336, 280], [250, 250]]],
    [[500, 0], [[468, 60], [320, 100], [320, 50], [300, 100], [300, 50], [300, 250], [336, 280], [250, 250]]],
    [[200, 0], [[320, 100], [320, 50], [300, 100], [300, 50], [300, 250], [300, 600], [336, 280], [250, 250]]],
    [[0, 0], []]
  ],
  MPU: [
    [[1400, 0], [[300, 600], [300, 250], [336, 280], [250, 250], [120, 600], [160, 600]]],
    [[1200, 0], [[300, 600], [300, 250], [336, 280], [250, 250], [120, 600], [160, 600]]],
    [[1000, 0], [[250, 250], [120, 600], [160, 600]]],
    [[700, 0], []],
    [[500, 0], []],
    [[200, 0], []],
    [[0, 0], []]
  ],
  Gutter: [
    [[1990, 0], [[300, 600], [300, 250], [336, 280], [250, 250], [120, 600], [160, 600]]],
    [[1690, 0], [[120, 600], [160, 600]]],
    [[1000, 0], []],
    [[700, 0], []],
    [[500, 0], []],
    [[200, 0], []],
    [[0, 0], []]
  ],
  Mapping6: [
    [[1400, 0], [[300, 250], [336, 280], [250, 250]]],
    [[1200, 0], [[300, 250], [336, 280], [250, 250]]],
    [[1000, 0], [[250, 250]]],
    [[700, 0], []],
    [[500, 0], []],
    [[200, 0], []],
    [[0, 0], []]
  ],
  Interstitial: [
    [[1400, 0], [[970, 600], [300, 600], [336, 280], [300, 250]]],
    [[1200, 0], [[970, 600], [300, 600], [336, 280], [300, 250]]],
    [[1000, 0], [[970, 600], [300, 600], [336, 280], [300, 250]]],
    [[700, 0], [[300, 600], [336, 280], [300, 250]]],
    [[500, 0], [[300, 600], [336, 280], [300, 250]]],
    [[200, 0], [[300, 600], [336, 280], [300, 250]]],
    [[0, 0], [[300, 600], [336, 280], [300, 250]]]
  ]
}

export default FORMATS
